
import firebase from '../components/auth/fire-base';

export default class Auth {

    constructor(){
        this.user = this.user.bind(this);
        this.upgradeUserToPro = this.upgradeUserToPro.bind(this);
        this.downgradeUserToFree = this.downgradeUserToFree.bind(this)
    }

/**
 * Get current user and user data
 */
    user(){
      return new Promise((resolve, reject) => {
            firebase.auth().onAuthStateChanged( async(user) => {
                if (user) {
                firebase
                    .firestore()
                    .collection("users")
                    .doc(user.uid)
                    .get().then( async(doc)=>{
                        const user = doc.data();// Ger user from users collection

                        // Checking user whenever get current suer.
                       
                        if(user.pro){
                            const expireDate = new Date(user.expireDate.seconds * 1000);
                            const today = new Date();
                            if( expireDate.getTime() < today.getTime()){
                                await this.downgradeUserToFree(doc.data())
                                resolve(doc.data()) 
                            }else{
                                resolve(doc.data()) 
                            }
                        }else{
                           resolve(doc.data()) 
                        }
                    })
                } else {
                    resolve(null)
                }
            })
        })
    }
/**
 * 
 * Upgrade user to Pro
 */
    async upgradeUserToPro(user){

        return new Promise((resolve, reject) => {
            firebase
                .firestore()
                .collection('users').doc(user.uid).set({
                pro: true,
            }, {merge: true}).then((res)=>{
                resolve ({...res, success: true})
            })
        })
    }
/**
 * downgrad user to Free.
 */
    async downgradeUserToFree(user){

        return new Promise((resolve, reject) => {
            firebase
                .firestore()
                .collection('users').doc(user.uid).set({
                pro: false,
                subscription: null,
                paymentmethod: null,
                paymentVerified: false,
            }, {merge: true}).then(( )=>{
                resolve ({ success: true})
            }).catch(()=>{
                resolve ({ success: false})
            })
        })
    }
/**
 * 
 * Cancelling Subscription
 * This function is invoked from src/components/dialogs/cancel-pro-dialog.js and src/comfirmation-modal.js
 *
 */
    async cancelSubsciption(cancelReason){

        const user = await this.user();
        return new Promise((resolve, reject) => {

            // Save current user to cancelled collection and set paymentVerified to false
            firebase.firestore().collection('cancelled').doc(user.uid).set({
                email:user.email,
                cancelDate:  new Date(),
                lengthOfTimeSubscribed:'undefined',
                cancelReason: cancelReason,
            },{merge: true}).then(()=>{
                firebase.firestore().collection('users').doc(user.uid).set({
                    paymentVerified: false,
                },{merge: true})
                .then(()=>{
                    resolve ({ success: true})
                    window.location.href="/profile"
                })
                
            }).catch((error)=>{
                console.log(error);
                resolve ({ success: false})
            })
        })
    }
/**
 * User Sign Out
 */
    signOut(){
        firebase.auth().signOut()
        localStorage.removeItem('cloude_pin_user_key')
        window.location.href="/";
    }
}